$font-size-default: 14px;
$margin-bottom-default: 1.5rem;
$light-grey: #ccc;

@mixin heading-styles($font-size, $margin-bottom: $margin-bottom-default) {
  font-size: $font-size;
  color: $dark;
  font-weight: 400;
  margin-bottom: $margin-bottom;
}

@mixin table-styles() {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  max-width: 100%;
}

body,
html,
#__next {
  font-family: 'Open Sans', Arial, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: $font-size-default;
}

body.noScroll {
  overflow: hidden;
}

@import '~@shelf/client-helpers/lib/sass/colors';
@import '~@shelf/client-helpers/lib/sass/vars';

h1 {
  @include heading-styles(36px);
}

h2 {
  @include heading-styles(24px);
}

h3 {
  @include heading-styles(20px);
}

h4 {
  @include heading-styles(18px);
}

h5 {
  @include heading-styles($font-size-default, $margin-bottom-default);

  font-weight: 600;
  text-transform: uppercase;
}

h6 {
  @include heading-styles($font-size-default, $margin-bottom-default);

  font-weight: 700;
  text-transform: uppercase;
}

@mixin article-styles() {
  font-weight: 400;
  color: $dark;
  line-height: 1.5;
  font-size: $font-size-default;
  word-wrap: break-word;
  word-break: break-word;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 20px;
  }

  table {
    @include table-styles();

    td,
    th {
      padding: 10px 5px;
    }
  }

  a * {
    text-decoration: underline;
    text-decoration-color: inherit;
  }

  ul {
    margin: inherit;
  }

  ul,
  ol {
    list-style-position: outside !important;
    padding-left: 30px;
  }

  blockquote {
    border-left: solid 2px $skyBlue !important;
    color: $dark !important;

    blockquote {
      border-left: solid 2px $brightBlue !important;

      blockquote {
        border-left: solid 2px $deepBlue !important;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    // Styles for bordered image
    &.fr-shadow {
      box-shadow: 0 0 5px 5px $lightGrey;
      border: none;
    }
  }
}

@mixin styled-article() {
  @include article-styles;

  box-sizing: border-box;
  margin-bottom: 30px;

  p {
    margin-top: 10px;
  }

  /* stylelint-disable */
  span > a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
  }

  a {
    color: $linkBlue;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      color: $linkBlue;
      text-decoration: none;
    }
  }

  table {
    @include table-styles();

    td {
      min-width: 5px;
    }

    td,
    th {
      border: 1px solid $lightGrey;
    }

    th {
      background: $popularGrey;
      font-weight: 600;
    }

    td:empty,
    th:empty {
      height: 20px;
    }

    .fr-dashed-borders td,
    .fr-dashed-borders th {
      border-style: dashed;
    }

    .fr-alternate-rows tbody tr:nth-child(2n) {
      background: $bgColor;
    }

    td.fr-highlighted,
    th.fr-highlighted {
      border: 1px double $red;
    }

    td.fr-thick,
    th.fr-thick {
      border-width: 2px;
    }
  }

  pre {
    overflow: visible;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  img {
    &.fr-bordered {
      border: 5px solid #ccc;
      box-sizing: content-box;
    }

    &.fr-rounded {
      border-radius: 10px;
      background-clip: padding-box;
    }

    &.fr-shadow {
      box-shadow: 0 0 5px 5px $lightGrey;
      border: none;
    }
  }

  .fr-video > iframe {
    max-width: 100%;
  }

  img,
  .fr-img-caption {
    &.fr-dii {
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin-left: 5px;
      margin-right: 5px;
      max-width: calc(100% - 10px);
    }

    &.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
      vertical-align: top;
    }

    &.fr-dii.fr-fil {
      float: left;
      margin: 5px 5px 5px 0;
      max-width: calc(100% - 5px);
    }

    &.fr-dii.fr-fir {
      float: right;
      margin: 5px 0 5px 5px;
      max-width: calc(100% - 5px);
    }

    &.fr-dib.fr-fil {
      margin-left: 0;
      text-align: left;
    }

    &.fr-dib.fr-fir {
      margin-right: 0;
      text-align: right;
    }
  }

  .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px 5px 10px;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    -moz-opacity: 0.9;
    opacity: 0.9;
    filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    width: 100%;
    text-align: center;
  }

  .fr-img-caption .fr-img-wrap {
    padding: 0;
    margin: auto;
    text-align: center;
    width: 100%;
  }

  @media (width <= 1275px) {
    margin-bottom: 15px;
  }

  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    table {
      min-width: calc(100% - #{20px});
      table-layout: fixed;
      width: calc(100% - #{20px});
    } /* IE11 */
  }
}

.ArticleBody {
  width: 100%;

  @include styled-article;
}

.StyledFooter {
  @include styled-article;

  table th {
    background: #ececec;
  }

  table {
    text-align: left;
  }
}

.CollapsibleSection {
  position: relative;
  background-color: $white;
  cursor: pointer;

  &--active {
    border-width: medium !important;
    position: relative;
  }

  &--selected {
    border: 1px solid #0065ff !important;
    border-width: 1px !important;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0065ff;
      opacity: 0.1;
      border-radius: 10px;
    }
  }

  &__top {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-weight: $semiBoldFontWeight;
    color: $dark;
    font-size: 1rem;
    text-align: left;
    border: none;
    transition: all 0.25s ease-out;
  }

  &__label {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 10px;
    left: 0;

    &--view {
      top: 0;
      left: 0;
      padding: 10px 0;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: calc(100% + 20px);
        height: 100%;
      }
    }
  }

  &__title {
    &--empty {
      &:before {
        content: attr(data-placeholder);
        color: $lightGrey;
      }
    }

    &:not(:focus):empty:before {
      content: attr(data-placeholder);
      color: $lightGrey;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    max-height: 100%;
    margin-top: 10px;
    overflow: hidden visible;
    transition: all 0.2s linear;
    color: $dark;
    cursor: text;

    &--is-view_mode {
      max-height: 0;
      margin-top: 0;
      overflow: hidden;
    }
  }

  &__toggle {
    &:checked + .CollapsibleSection__top {
      .CollapsibleSection__icon {
        transform: rotate(90deg);
      }
    }
  }

  &__toggle:checked ~ &__content {
    max-height: max-content;
    margin-top: 10px;
    transition: all 0.2s linear;
  }

  &__close {
    display: flex !important;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    padding: 5px;
    box-shadow:
      0 3px 5px -1px rgb(0 0 0 / 0.2),
      0 6px 10px 0 rgb(0 0 0 / 0.14),
      0 1px 18px 0 rgb(0 0 0 / 0.12);
    border-radius: 6px;
    background-color: #fff;
    transition: 0.6s;
    z-index: 50;

    &:hover {
      /* stylelint-disable scss/no-global-function-names */
      background-color: darken(#fff, 5%);
      cursor: pointer;
    }
  }
}
