.SharePageUrl {
  display: block;
  position: fixed;
  z-index: 10000;
  top: 75px;
  right: 15px;
  min-width: 416px;
  width: 416px;
  animation: toast-in-top 1s;

  @media print {
    display: none;
  }
}

.SharePageUrl--hidden {
  opacity: 0;
  animation: hide-toast 0.5s;
}

@media only screen and (width <= 992px) {
  .SharePageUrl {
    right: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
  }
}

.container {
  min-height: 40px;
  width: 100%;
  background-color: #fff;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-item-align: center;
  align-self: center;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

.body {
  display: flex;
  align-items: center;
}

.label {
  flex-grow: 1;
  font-size: 14px;
  hyphens: manual;
  overflow: hidden;
  padding: 14px 0 14px 19px;
  word-break: break-word;
}

.checkHolder {
  align-items: center;
  background-color: rgb(60, 167, 65);
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.crossHolder {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.crossHolder:hover {
  background-color: #eee;
}

@keyframes toast-in-top {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hide-toast {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateX(50);
  }
}

.iconButton {
  background-color: transparent;
  border: 0;
  padding: 0;
}
